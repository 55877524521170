










































































import { Component, Prop, Vue } from 'vue-property-decorator'

import Chat from '@/components/list/Chat.vue'
import Tasks from '@/components/list/Tasks.vue'
import Entity from './partials/Entity.vue'
import CircleChart from '@/components/misc/CircleChart.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import Notifications from '@/components/list/Notifications.vue'

import { ImplementationResource } from '@/models/implementations/ImplementationResource'
import { UserResource } from '@/models/users/UserResource'

@Component({
  components: {
    Chat,
    Tasks,
    Entity,
    CircleChart,
    SmallLoader,
    Notifications,
  },
})
export default class ProjectDashboard extends Vue {
  @Prop()
  private readonly implementation!: ImplementationResource

  private implementationActivityTab = 'activity'

  private users: UserResource[] = []

  private get getImplementationName(): string {
    if (this.implementation) {
      return `${this.implementation.name}`
    }

    return ''
  }

  private created(): void {
    this.getUsers()
  }

  private async getUsers(): Promise<void> {
    const data = await this.implementation.getAllUsers()
    this.users = data.data
  }

  private goToDocument(): void {
    this.$router.push({ name: 'projects-flexible-document', params: { implementation_id: `${this.implementation.id}` } })
  }
}
